.cutom_select div {
  min-height: 46px;
  padding: 0 15px;
}
.cutom_select div:before,
.cutom_select div:after {
  display: none;
}
.cutom_select div input {
  color: #545454 !important;
  font-weight: bold;
}
.cutom_select div input::placeholder {
  color: #545454 !important;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
}
