body {
  background: #f9f9fa;
  font-family: 'Lato', sans-serif;
}
@font-face {
  font-family: 'DDC Hardware';
  src: url('DDC-Hardware-font/DDCHardware-Regular.eot');
  src: local('DDC-Hardware-font/DDC Hardware Regular'),
    local('DDCHardware-Regular'),
    url('DDC-Hardware-font/DDCHardware-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('DDC-Hardware-font/DDCHardware-Regular.woff2') format('woff2'),
    url('DDC-Hardware-font/DDCHardware-Regular.woff') format('woff'),
    url('DDC-Hardware-font/DDCHardware-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /* user-drag: none; */
}
.main-logo{
  max-width: 124px;
  height: auto;
}
/* VG logo */
svg.main-logoVg {
  max-width: 106px;
  height: auto;
}
svg.main-logoVg g {
  fill: #fff;
}
/* end Vg logo */
.MuiAppBar-colorPrimary.app-topbar {
  color: #000000;
  background-color: #f9f9fa;
}
.title-bk h3,
.title-bk h1 {
  margin: 0px 0 21px;
  font-family: 'Lato', sans-serif;
}
.title-bk h1 {
  color: #292929;
  font-size: 32px;
  font-weight: bold;
  display: inline-block;
  line-height: 42px;
}
.title-bk .btn-cancel {
  float: right;
  color: #01847a;
  border: 1px solid #01847a;
  padding: 10px 16px;
  margin-bottom: 15px;
  min-height: 48px;
  border-radius: 2px;
}
.title-bk h3 {
  color: #949494;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 24px;
}
.title-bk h3 span {
  color: #01847a;
}
.sp-stepLabel .MuiStepIcon-active,
.sp-stepLabel .MuiStepIcon-completed {
  color: #01847a !important;
}
.title-bk h3 label {
  margin: 0 10px;
}
/* .MuiStepContent-root.sp-text .MuiGrid-root {
  float: right;
  margin-top: -25px;
  width: 100%;
} */
.sp-stepLabel {
  display: inline-flex;
}
.step-btn .Mui-disabled {
  display: none;
}
button.btn-next {
  float: right;
}
.step-btn {
  padding: 25px 0px;
  display: inline-block;
  width: 100%;
  float: right;
}
form.stap-form .MuiGrid-root.MuiGrid-item {
  margin: 0 0;
}
.fd-input input {
  border: 1px solid #a9a9a9;
  height: 30px;
  padding: 8px 15px 8px;
  margin-top: 6px;
}
.fd-input input:focus {
  border: 1px solid #01847a;
}
.MuiGrid-root.program-input {
  padding-right: 15px;
}
.stap-form .MuiFormControl-fullWidth {
  width: 100%;
  margin-bottom: 20px;
}
.btn {
  border-radius: 0 !important;
}
.clearfix {
  clear: both;
  display: table;
}
.user-info {
  display: inline-flex;
  align-items: center;
  margin: 0px 15px;
}
.user-info h4 {
  margin: 0 15px;
  font-weight: 900;
}
.list-items {
  padding: 0 !important;
}
/* .list-items .MuiButton-label,
.list-collapse .MuiButton-label{
    justify-content: start;
} */
.side-nav .MuiDrawer-paperAnchorDockedLeft {
  border-right: 0;
}
header.app-topbar {
  box-shadow: none;
}

.step-btn .MuiButton-containedPrimary {
  background-color: #01847a;
}
.step-btn .MuiButton-containedPrimary:hover {
  background-color: #01847a;
}
.stap-3 {
  padding-top: 10px;
  margin: 0 0 !important;
}

.switch-btn {
  padding: 0 12px !important;
}
.btn-previou {
  color: #01847a !important;
  border: 1px solid #01847a !important;
  border-radius: 0 !important;
}
.nav-end {
  background: #ffffff;
  box-shadow: 4px 0px 16px rgba(37, 40, 43, 0.08);
  border-radius: 5px;
  height: auto; /* as per mini Nav  */
  padding: 0px;
  bottom: 0px;
  position: fixed;
  display: inline-flex;
  align-items: center;
  max-width: 260px;
  margin: 10px 15px 15px;
}
.nav-end li {
  display: inline-block !important;
  width: auto !important;
  padding: 0;
  word-break: initial;
}
.nav-end li a {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 2px;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  align-items: center;
  display: block;
  text-align: center;
}
.nav-end li a img {
  display: block;
  text-align: center;
  margin: 0 auto 5px;
}
.nav-end li span {
  font-weight: 700;
}
.nav-end li:hover span {
  color: #01847a;
}
.nav-end li:hover span svg path,
.nav-end li:hover button span svg rect {
  fill: #01847a;
}
.nav-end li button[aria-describedby='other-popover'] span,
.nav-end li button[aria-describedby='other-popover'] span svg rect {
  color: #37847a;
  fill: #37847a;
}
.side-nav .MuiPaper-root {
  background: #f9f9fa;
}

/* main card  */
.main-card {
  background: #ffffff;
  box-shadow: 4px 0px 16px rgba(37, 40, 43, 0.08);
  border-radius: 5px;
}

.text-limit {
  font-size: 12px;
  line-height: 16px;
  color: #a9a9a9;
  text-align: right;
  display: block;
}

.btnPrimary {
  background: #01847a;
  color: #ffffff;
}
.fd-input .MuiSelect-selectMenu {
  border: 1px solid #a9a9a9;
  height: 30px;
  padding: 6px 15px 7px;
  margin-top: 6px;
}
/* cutom_select */
.cutom_select div {
  min-height: 46px;
  padding: 0 15px;
}
.cutom_select div:before,
.cutom_select div:after {
  display: none;
}
.cutom_select div input {
  color: #545454 !important;
  font-weight: bold;
}
.cutom_select div input::placeholder {
  color: #545454 !important;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.5px;
}
/* -----Email Toolbar------- */
.emailtoolbar .ql-select {
  width: 200px;
  display: inline-block;
  vertical-align: middle;
  border-right: 1px solid #c4c4c4;
}
.emailtoolbar .ql-toolbar.ql-snow {
  border-bottom: 0;
  padding: 0;
  background: #fafafa;
}
.emailtoolbar .ql-toolbar span.ql-select .cutom_select div {
  min-height: 37px;
}
.emailtoolbar .ql-toolbar .ql-select .MuiAutocomplete-root {
  background: #fafafa;
  border: 0;
}
.emailtoolbar .ql-toolbar .ql-picker.ql-font,
.emailtoolbar .ql-toolbar .ql-header.ql-picker {
  border-right: 1px solid #c4c4c4;
  height: 37px;
  padding: 0;
  display: flex;
  align-items: center;
}
.emailtoolbar .ql-toolbar .ql-picker.ql-font .ql-picker-label,
.emailtoolbar .ql-toolbar .ql-header.ql-picker .ql-picker-label {
  border: 1px solid transparent;
  align-items: center;
  display: flex;
}

/* react-quill editor */

.ql-container {
  font-family: 'Lato' !important;
}
.ql-container.ql-snow {
  min-height: 200px;
  /* max-height: 300px; */
  overflow-y: auto;
}
/*quill Editor Font setting */
.htmldata h1 {
  font-size: 52px;
  line-height: 64px;
  letter-spacing: 0.2px;
}
.htmldata h2 {
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 0px;
}
.htmldata h3 {
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.1px;
}
.htmldata h4 {
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.2px;
}
.htmldata h5 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.2px;
}
.htmldata h6 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}
.ql-snow .ql-editor h1 {
  font-size: 52px !important;
  line-height: 64px;
  letter-spacing: 0.2px;
  font-weight: normal;
}
.ql-snow .ql-editor h2 {
  font-size: 44px !important;
  line-height: 54px;
  letter-spacing: 0px;
  font-weight: normal;
}
.ql-snow .ql-editor h3 {
  font-size: 32px !important;
  line-height: 42px;
  letter-spacing: 0.1px;
  font-weight: normal;
}
.ql-snow .ql-editor h4 {
  font-size: 26px !important;
  line-height: 32px;
  letter-spacing: 0.2px;
  font-weight: normal;
}
.ql-snow .ql-editor h5 {
  font-size: 20px !important;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-weight: normal;
}
.ql-snow .ql-editor h6 {
  font-size: 18px !important;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-weight: normal;
}
.sun-editor-editable.data_normal_screen h1,
.sun-editor-editable.data_normal_screen h2,
.sun-editor-editable.data_normal_screen h3,
.sun-editor-editable.data_normal_screen h4,
.sun-editor-editable.data_normal_screen h5,
.sun-editor-editable.data_normal_screen h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}
.sun-editor-editable.data_full_screen h1,
.sun-editor-editable.data_full_screen h2,
.sun-editor-editable.data_full_screen h3,
.sun-editor-editable.data_full_screen h4,
.sun-editor-editable.data_full_screen h5,
.sun-editor-editable.data_full_screen h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald:wght@500&family=Roboto:wght@300&display=swap');
.ql-picker.ql-font .ql-picker-label[data-value='Lato']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
  font-family: 'Lato';
  content: 'Lato' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
  font-family: 'Roboto';
  content: 'Roboto' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Opensans']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Opensans']::before {
  font-family: 'Open Sans', sans-serif;
  content: 'Open sans' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Oswald']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Oswald']::before {
  font-family: 'Oswald', sans-serif;
  content: 'Oswald' !important;
}

.ql-font-Lato {
  font-family: 'Lato';
}
.ql-font-Roboto {
  font-family: 'Roboto';
}
.ql-font-Opensans {
  font-family: 'Open Sans', sans-serif;
}
.ql-font-Oswald {
  font-family: 'Oswald', sans-serif;
}

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  padding-left: 16px !important;
}
.ql-editor pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}
.ql-editor pre {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  padding: 5px 10px !important;
  border-radius: 3px;
}

.ql-snow .ql-tooltip {
  position: absolute;
  transform: translate(131px) !important;
}

/*  End quill editor */

/* calendar */
.rbc-time-view {
  border: 0px solid #ddd;
}
.rbc-time-view .rbc-row.rbc-time-header-cell {
  background: #f9f9fa;
  border: 0;
  min-height: 36px;
  justify-content: center;
  padding: 8px 0;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
}
.rbc-time-view .rbc-row.rbc-time-header-cell .rbc-header {
  font-size: 12px;
}
.rbc-time-view .rbc-row.rbc-time-header-cell .rbc-header {
  border-bottom: 0px solid #ddd;
}
.rbc-time-view .rbc-row.rbc-time-header-cell .rbc-header + .rbc-header {
  border-left: 0px solid #ddd;
  background: transparent;
}
.rbc-time-view .rbc-label.rbc-time-header-gutter {
  background: #f9f9fa;
  max-height: 48px;
}
.rbc-time-view .rbc-time-header-content {
  border-left: 0px solid #ddd;
}
.rbc-time-view .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-row.rbc-time-header-cell .rbc-header a {
  color: #545454;
  vertical-align: middle;
}
/* end calendar */

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: #01847a #f9f9fb;
}
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: #f9f9fb;
}
*::-webkit-scrollbar-thumb {
  background-color: #01847a;
  border-radius: 20px;
}
/* end scrollbar */

/* .filterAutoComplete {
  min-height: 38px;
} */

/* memberprofile user image */
.wrapperimgBox {
  display: flex;
  flex-direction: column;
  height: 150px;
  position: relative;
  width: 150px;
}
.imageUploaderBox {
  height: 150px;
  width: 150px;
  border: 1px dashed #a9a9a9;
  min-height: 150px;
  border-radius: 8px;
  position: relative;
}
.Clicktextinfo {
  top: 0%;
  left: -18%;
  margin: 0 auto;
  position: absolute;
  font-size: 14px;
  transform: translate(50%, 50%);
  text-align: center;
  font-weight: bold;
  line-height: 18px;
}
.editimageup,
.EditImageBk,
.imgDelBtnup {
  display: none;
}

/* End memberprofile user image */

/* Lead profile tags css */
.TagGray {
  color: #a8a8a8 !important;
  background: rgba(41, 41, 41, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0) !important;
}
.TagGray.ReactTags__remove {
  color: #a8a8a8 !important;
  border: 1px solid #a8a8a8 !important;
}

/* masterhq  */
.DiscountBlock .MuiFormControl-root.MuiFormControl-fullWidth {
  width: auto;
}
.DiscountBlock .RadioGroupBk {
  display: inline-flex;
  margin-right: 15px;
}

/* vanguard */
.customGridData .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 123px;
  justify-content: center;
}

/* Safari 11+  member profile tags */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .AlertslistSafari {
      padding: 0 5px;
    }
  }
}

/* Sign Waiver  */
.signStepperQuestionnaireMeta .MuiStepper-vertical div:nth-child(4),
.signStepperQuestionnaireMeta .MuiStepper-vertical div:nth-child(5) {
  display: none;
}

/* vanguard waiver new */
.VanguardStepperQuestionnaire-new .StepLabelInfo div:nth-child(7) svg {
  color: rgb(51, 51, 51) !important;
}
.VanguardStepperQuestionnaire-new .StepLabelInfo div:nth-child(7) {
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}
/* end vanguard waiver new */

/* vanguard waiver returning */
.VanguardStepperQuestionnaire div:nth-child(9) svg {
  color: rgb(51, 51, 51) !important;
}
.VanguardStepperQuestionnaire div:nth-child(9) {
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}
/* end vanguard waiver returning */
.circular-portrait {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
}

.circular-portrait img {
  width: 100%;
  height: auto;
}

/* Master HQ MuiAutocomplete */
/* body div.MuiAutocomplete-popper[role='presentation'] .MuiPaper-root {
  background: #3c3d4f;
  color: #fff;
}
body
  div.MuiAutocomplete-popper[role='presentation']
  .MuiPaper-root
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option {
  color: #fff;
} */

@keyframes circleSVG{
  0%{
  transform:rotate(0deg)
  translate(0px)
  rotate(0deg);
  
  }
  100%{
  transform:rotate(-3600deg)
  translate(0px)
  rotate(-360deg);
  }
  }

  /* MiniMenu ber  */
body.MiniMenuOpen .desktopMenu .MuiDrawer-paperAnchorLeft {
    display: block;
    width: 285px; /* update css :- desktopDrawer on navBar.style.js */
    height: calc(100% - 195px);
}

body .desktopMenu .MuiDrawer-paperAnchorLeft {
    /* display: block; */
    width: 90px;
    height: calc(100% - 305px);
}

body .wrapperContent {
  padding-left: 60px;
}

/* .MiniMenuOpen span.makeStyles-listName-65 {
  display: block;
} */
/* sidebar  */
.MiniMenuOpen .list-items span.MuiButton-label span {
  display: block;
}
.list-items span.MuiButton-label span {
  display: none;
}
.list-collapse .MuiList-root {
  display: none;
}
.list-items .MuiButtonBase-root {
  justify-content: center;
}
.MiniMenuOpen .list-items .MuiButtonBase-root {
  justify-content: flex-start;
}
.MiniMenuOpen .list-collapse .MuiList-root {
  display: block;
}
.list-collapse-mimiMenu .MuiPopover-paper{
  overflow-x: visible;
  overflow-y: visible;
  background-color: #F9F9FA;

}
.list-collapse-mimiMenu span.MuiButton-label span {
  padding-left: 0;
}
.list-collapse-mimiMenu .MuiList-root {
  min-width: 145px;
}
.list-collapse-mimiMenu .MuiList-root .MuiListSubheader-sticky{
  font-weight: 900;
  font-size: 14px;
  color: #7F7F7F;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.list-collapse-mimiMenu .MuiButton-root {
  justify-content: flex-start;
  max-height: 48px;
  font-weight: 400;
  padding: 10px 30px;
}

.MiniMenuOpen .nav-end {
  height: 60px;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 4px 0px 16px rgb(37 40 43 / 8%);
}
.nav-end {
  flex-direction: column;
  z-index: 1200;
  background: #f9f9fa;
  box-shadow: none;
}
.nav-end li .MuiButton-root {
  line-height: normal !important;
}
.MiniMenuOpen .nav-end li .MuiButton-root{
  line-height: 16px;
}
.nav-end li span {
  font-size: 0;
}

.MiniMenuOpen .nav-end li span{
  font-size: 12px;
}
.nav-end li .MuiButton-root::before{
  display: none;
}
.MiniMenuOpen .nav-end li .MuiButton-root::before{
  display: block;
}

.miniMenu-wrapper-open .sub-othermenu {
  width: auto;
}
.miniMenu-wrapper-open .MuiPaper-root {
  overflow-y: visible !important;
  overflow-x: visible;
  margin-top: 60px;
  margin-left: 60px;
}

body .miniMenu-wrapper-open .MuiPaper-root::before {
  left: -4px;
  width: 9px;
  bottom: 45px;
  height: 9px;
  content: "";
  position: absolute;
  transform: rotate(45deg);
  background: #ffffff;
  /* z-index: 9999; */
}